@use "sass:math";

$title-font-stack: monospace;
$body-font-stack: monospace;
$main-background-color: #ebe9e9;
$main-text-color: #3a3a3a;
$highlight-color: #e85c41;
$body-font-color: #59594a;

$spacing-unit: 30px !default;

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: math.div($spacing-unit, 2);
}

/* Custom CSS */

body {
  background: #dbe1f1;
  font-family: $body-font-stack;
  max-width: 960px;
  margin: 0 auto;
  
}

hr.dashed {
  border-style: dashed;
}

article {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    text-align: center;
  }

  font-size: 16px;
}
